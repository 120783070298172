<template>
  <div class="coach fill-white container-shadow p-24" v-if="tutor">
    <div class="coach__img">
      <div class="avatar avatar_big container-shadow" :style="{backgroundImage: 'url(' + (tutor.user.avatar ? tutor.user.avatar['500x'] : '/pic/user-pic.jpeg') + ')'}"></div>
    </div>
    <div class="coach__content">
      <div class="coach__title mb-24">{{ tutor.fullname }}</div>
      <div class="coach__content-item coach__content-item_location">
        <div class="coach__label">Регион</div>
        <p>{{ tutor.region ? tutor.region.name : '' }}</p>
      </div>
      <div class="coach__content-item coach__content-item_info">
        <template v-if="tutor.statuses && showStatus">
          <span v-for="(spec, index) in tutor.statuses.full" :key="index">
            <div class="coach__label mb-24">
              {{ spec.title }} по программе &laquo;{{ spec.program }}&raquo;
            </div>
            <ul class="list list_dotted hidden-content">
              <li v-for="(course, index) in spec.courses" :key="index">
                {{ course }}
              </li>
            </ul>
          </span>
        </template>
      </div>
      <div v-if="tutor.experience && showExperience" class="coach__content-item coach__content-item_experience">
        <div class="coach__label">Опыт и стаж работы:</div>
        <span v-html="tutor.experience"></span>
      </div>
      <div v-if="tutor.achievements && showAchievements" class="coach__content-item coach__content-item_experience">
        <span v-html="tutor.achievements"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TutorItem",
  props: {
    tutor: {
      type: Object,
      required: true
    },
    showStatus: {
      type: Boolean,
      default() {
        return true
      }
    },
    showExperience: {
      type: Boolean,
      default() {
        return true
      }
    },
    showAchievements: {
      type: Boolean,
      default() {
        return false
      }
    }
  }
}
</script>

<style scoped>

</style>